import * as React from "react"

import { clsx } from "clsx"

const LoadingSpinner = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={clsx("animate-spin", className)}
    {...props}
  >
    <path
      opacity="0.4"
      d="M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346625 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346625 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C15.9976 10.121 15.154 12.1545 13.6542 13.6542C12.1545 15.154 10.121 15.9976 8 16ZM8 2C6.81332 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17055C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C13.9984 6.40919 13.3658 4.88399 12.2409 3.75912C11.116 2.63424 9.59082 2.00159 8 2Z"
      fillOpacity="0.439"
    />
    <path d="M16 8H14C13.9984 6.40919 13.3658 4.88399 12.2409 3.75911C11.116 2.63424 9.59081 2.00159 8 2V0C10.121 0.00238211 12.1544 0.846002 13.6542 2.34578C15.154 3.84555 15.9976 5.879 16 8Z" />
  </svg>
)

export default LoadingSpinner
